import React from 'react'
import styles from "./Partners.module.css"
import { PartnersList } from "./partnersList"

function Partners() {
    return (
        <div id={styles.partnersContainer}>
            {PartnersList.map((item, index) => (
                <div className={styles.imgContainer}>
                    <img className={styles.partnerImg}
                        key={index}
                        src={require('../../assets/New_Partners/' + item)}
                        alt={`partner no. ${index + 1}`}
                        loading="lazy"
                    />
                </div>
            ))}
        </div>
    )
}

export default Partners